.editIcon {
  padding: 6px;
  box-shadow: -4px 10px 22px rgba(47, 42, 137, 0.42);
  border-radius: 12px;
}

.spaceHeading {
  font-weight: 500;
}

.spaces {
  font-weight: 500;
  font-size: 12px;
  color: #69768b;
}

.estimatedSolarGeneration {
  background-color: #f4f8ea;
  border-radius: 8px;
}
