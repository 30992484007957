.solrChartOverflow {
  overflow-y: auto;
  height: calc(100vh - 290px);
}

@media (max-width: 1199px) {
  .solrChartOverflow {
    height: calc(100vh - 380px);
  }
}

@media (max-width: 991px) {
  .solrChartOverflow {
    height: calc(100vh - 440px);
  }
}

@media (max-width: 767px) {
  .solrChartOverflow {
    height: calc(100vh - 510px);
  }
}

@media (max-width: 575px) {
  .solrChartOverflow {
    height: calc(100vh - 610px);
  }
}
