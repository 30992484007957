.title {
  font-family: "Poppins";
  font-size: 10px;
  color: #69768b;
  font-weight: 500;
  line-height: 12px;
}

.label {
  border-radius: 9.7px;
  color: #383941;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
}

.normal {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.small {
  font-weight: 600;
  font-size: 9px;
}
